import React, { Fragment, useEffect, useState } from "react"
import { Button, Card, CardTitle } from "components/UI"
import ContractInstallment from "./ContractInstallment"
import ContractInstallmentStore from "stores/ContractInstallmentStore/List"
import Accordion from "react-bootstrap/Accordion"
import BCard from "react-bootstrap/Card"
import formatDate from "utils/formatDate"
import formatMoney from "utils/formatMoney"

const contractInstallmentStore = ContractInstallmentStore.create()

const getDate = (contract_installment) => {
  if (contract_installment.sp_sk_payment_on) return formatDate(contract_installment.sp_sk_payment_on)
  if (contract_installment.sp_payment_on) return formatDate(contract_installment.sp_payment_on)
  return "-"
}

const ContractInstallments = ({ push, form, store, ...h }) => {
  useEffect(() => {
    contractInstallmentStore.fetchMeta()
  }, [])

  const [activeKey, setActiveKey] = useState(null)

  // useEffect(() => {
  //   setActiveKey((form.values.contract_installments.length - 1).toString())
  // }, [form.values.contract_installments.length])

  const onAddClick = () => {
    push({
      avrs: [],
      sp_payment_on: null,
      sp_payment_cost: 0,
      sp_payment_kind: { value: 0 },
      sp_sk_payment_on: null,
      sp_sk_payment_cost: null,
      sp_sk_payment_form: { value: 0 },
      sp_sinergy_payment_on: null,
      sp_sinergy_payment_cost: null,
      sp_sinergy_payment_form: { value: 0 },
      fix_kv_cost: 0
    })
    setActiveKey(form.values.contract_installments.length.toString())
  }

  const contract_installments = form.values.contract_installments.map((contract_installment, index) => {
    let background = "#f8f9fc"
    if (
      contract_installment.payment_on &&
      contract_installment.payment_cost &&
      contract_installment.payment_cost !== "0.00" &&
      contract_installment.sp_sk_payment_on &&
      (contract_installment.sp_sk_payment_cost || contract_installment.sp_sk_payment_cost !== "0.00")
    ) {
      background = "#c3e6cb"
    }
    if (
      contract_installment.sp_payment_on &&
      contract_installment.sp_payment_cost &&
      contract_installment.sp_payment_cost !== "0.00" &&
      !contract_installment.sp_sk_payment_on &&
      contract_installment.sp_sk_payment_cost !== "0.00"
    ) {
      background = "#ffeeba"
    }
    if (
      !contract_installment.payment_on &&
      (!contract_installment.payment_cost || !contract_installment.payment_cost === "0.00") &&
      !contract_installment.sp_sk_payment_on &&
      (!contract_installment.sp_sk_payment_cost || contract_installment.sp_sk_payment_cost === "0.00")
    ) {
      background = "#f5c6cb"
    }
    return (
      <ContractInstallment
        {...{
          key: `${index}`,
          index,
          contract_installment,
          store: contractInstallmentStore,
          form,
          h,
          activeKey,
          setActiveKey
        }}
      />
    )
  })

  if (form.values.cargo_kind.id.toString() === "1") {
    return null 
  }

  return (
    <Fragment>
      <Card>
        {form.values.cargo_kind.id.toString() !== "1" && (
          <Fragment>
            <Button variant="success" onClick={onAddClick}>
              Добавить платеж
            </Button>
            <br />
            <br />
          </Fragment>
        )}
        <Accordion defaultActiveKey="0" activeKey={activeKey}>
          {contract_installments.reverse()}
        </Accordion>
      </Card>
    </Fragment>
  )
}

export default ContractInstallments
