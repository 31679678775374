import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"

const DashboardStore = types
  .model("DashboardStore", {
    data: types.optional(types.frozen(), { quarter: {}, year: {} }),
    meta: types.optional(types.frozen(), {}),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      return instance
        .get("/dashboards", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default DashboardStore
