import React, { Fragment } from "react"
import { observer } from "mobx-react"
import formatMoney from "utils/formatMoney"
import formatPercent from "utils/formatPercent"

const Table = ({ store }) => {
  if (store.data.table.length === 0) return null
  return (
    <div style={{ overflowX: "scroll" }}>
      <table className="table table-bordered table-striped table-hover" style={{ width: "3300px" }}>
        <thead className="thead-light">
          <tr>
            <th scope="col" colSpan="2"></th>
            <th scope="col">Янв</th>
            <th scope="col">Фев</th>
            <th scope="col">Март</th>
            <th scope="col" style={{ background: "#fef2d0" }}>
              1КВ
            </th>
            <th scope="col">Апр</th>
            <th scope="col">Май</th>
            <th scope="col">Июнь</th>
            <th scope="col" style={{ background: "#fef2d0" }}>
              2КВ
            </th>
            <th scope="col">Июль</th>
            <th scope="col">Авг</th>
            <th scope="col">Сент</th>
            <th scope="col" style={{ background: "#fef2d0" }}>
              3КВ
            </th>
            <th scope="col">Окт</th>
            <th scope="col">Ноя</th>
            <th scope="col">Дек</th>
            <th scope="col" style={{ background: "#fef2d0" }}>
              4КВ
            </th>
            <th scope="col">Год</th>
          </tr>
        </thead>
        <tbody>
          {store.data.table.map((row, index) => (
            <Fragment>
              <tr style={index === store.data.table.length - 1 ? { background: "#e5efdc" } : {}}>
                <td rowSpan="3">{row.subdivision.short_name}</td>
                <td>План</td>
                {row.plan.months.map((value, month) => (
                  <Fragment key={`item=${month}`}>
                    <td>{formatMoney(value)}</td>
                    {(month + 1) % 3 === 0 && (
                      <td style={{ width: "200px", background: "#fef2d0" }}>
                        {formatMoney(row.plan.quarters[(month - 2) / 3])}
                      </td>
                    )}
                  </Fragment>
                ))}
                <td>{formatMoney(row.plan.year)}</td>
              </tr>
              <tr style={index === store.data.table.length - 1 ? { background: "#e5efdc" } : {}}>
                <td>Факт</td>
                {row.fact.months.map((value, month) => (
                  <Fragment key={`item=${month}`}>
                    <td>{formatMoney(value)}</td>
                    {(month + 1) % 3 === 0 && (
                      <td style={{ width: "200px", background: "#fef2d0" }}>
                        {formatMoney(row.fact.quarters[(month - 2) / 3])}
                      </td>
                    )}
                  </Fragment>
                ))}
                <td>{formatMoney(row.fact.year)}</td>
              </tr>
              <tr style={index === store.data.table.length - 1 ? { background: "#e5efdc" } : {}}>
                <td>% исп.</td>
                {row.percent.months.map((value, month) => (
                  <Fragment key={`item=${month}`}>
                    <td>{formatPercent(value)}</td>
                    {(month + 1) % 3 === 0 && (
                      <td style={{ width: "200px", background: "#fef2d0" }}>
                        {formatPercent(row.percent.quarters[(month - 2) / 3])}
                      </td>
                    )}
                  </Fragment>
                ))}
                <td>{formatPercent(row.percent.year)}</td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default observer(Table)
