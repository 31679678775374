import React, { useContext, useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import ContractFormContext from "contexts/ContractFormContext"
import AuthStoreContext from "contexts/AuthStoreContext"
import { useFormikContext } from "formik"
import UserStore from "stores/UserStore/List"

const userStore = UserStore.create()

const UserSelect = ({
  name = "user",
  label = "Исполнитель",
  required = false,
  setCurrentUser = false,
  subdivisionName = null,
  ...props
}) => {
  useEffect(() => {
    userStore.search({ limit: 0 })
  }, [])
  // const { userStore } = useContext(ContractFormContext)
  // const {
  //   values: { user },
  //   setFieldValue
  // } = useFormikContext()

  const { values, setFieldValue } = useFormikContext()
  const user = values[name]

  const { data: currentUser } = useContext(AuthStoreContext)

  const userOptions = user.id ? [{ label: user.name, value: user.id }] : userStore.userOptions

  useEffect(() => {
    if (setCurrentUser && user.id === null) setFieldValue(name, { id: currentUser.id, name: currentUser.name })
  }, [])

  const loadUserOptions = (inputValue) =>
    userStore.search({ filter: { by_name: inputValue }, limit: 0 }).then(({ userOptions }) => userOptions)

  const onChange = (event) => {
    const { value, label, data } = event === null ? { value: null, label: null, data: null } : event
    setFieldValue(`${name}`, { id: value, name: label })
    if (subdivisionName && data) {
      setFieldValue(subdivisionName, { id: data.subdivision.id, name: data.subdivision.name })
    }
  }

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={`${name}.id`}
        label={label}
        required={required}
        options={userOptions}
        isSearchable
        loadOptions={loadUserOptions}
        onChange={onChange}
        {...props}
      />
    </div>
  )
}

export default observer(UserSelect)
